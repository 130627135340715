document.addEventListener('DOMContentLoaded',function() {

    const video = document.getElementsByClassName('js-video')[0];
    
    const init = function() {
        video.addEventListener('click', function() {
        
            const playBtn = document.getElementsByClassName('js-playBtn')[0];
            
            const playVideo = function() {
                
                playBtn.classList.add('is-fadeout');
                
                setTimeout(function() {
                    playBtn.classList.add('is-hidden');
                }, 200);
                
                video.play();
            };
            
            const pauseVideo = function() {
                
                playBtn.classList.remove('is-hidden');
                
                setTimeout(function() {
                    playBtn.classList.remove('is-fadeout');
                }, 1);
                
                video.pause() ;
            };
            
            video.paused ? playVideo() : pauseVideo();
            
        });
    };
    

    video ? init() : false;

}, false);
