import css from './sass/style.scss';

require('./js/polyfills.js');
require('./js/counter.js');
require('./js/fonts.js');
require('./js/init.js');
require('./js/ismobile.js');
require('./js/map.js');
require('./js/video.js');

require('./img/icons/location--green.svg');
require('./img/icons/location--red.svg');

require('./img/photos/top.jpg');
require('./img/photos/top@2x.jpg');

require('./img/photos/01.jpg');
require('./img/photos/01@2x.jpg');
require('./img/photos/02.jpg');
require('./img/photos/02@2x.jpg');
require('./img/photos/video.jpg');
require('./img/photos/video@2x.jpg');

require('./img/video/Toyota_Yaris_2020_logo.mp4');
require('./img/video/Toyota_Yaris_2020_logo.webm');
